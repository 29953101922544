@import './css/main.css';
@import './css/calendar.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'ALDI SUED OT';
    src: url('/public/fonts/ALDISUEDOT-Regular.eot');
    src: url('/public/fonts/ALDISUEDOT-Regular.eot?#iefix') format('embedded-opentype'),
    url('/public/fonts/ALDISUEDOT-Regular.woff2') format('woff2'),
    url('/public/fonts/ALDISUEDOT-Regular.woff') format('woff'),
    url('/public/fonts/ALDISUEDOT-Regular.ttf') format('truetype'),
    url('/public/fonts/ALDISUEDOT-Regular.svg#ALDISUEDOT-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALDI SUED OT';
    src: url('/public/fonts/ALDISUEDBold.eot');
    src: url('/public/fonts/ALDISUEDBold.eot?#iefix') format('embedded-opentype'),
    url('/public/fonts/ALDISUEDBold.woff2') format('woff2'),
    url('/public/fonts/ALDISUEDBold.woff') format('woff'),
    url('/public/fonts/ALDISUEDBold.ttf') format('truetype'),
    url('/public/fonts/ALDISUEDBold.svg#ALDISUEDBold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Engrace';
    src: url('/public/fonts/Engrace-Semibold.eot');
    src: url('/public/fonts/Engrace-Semibold.eot?#iefix') format('embedded-opentype'),
    url('/public/fonts/Engrace-Semibold.woff2') format('woff2'),
    url('/public/fonts/Engrace-Semibold.woff') format('woff'),
    url('/public/fonts/Engrace-Semibold.ttf') format('truetype'),
    url('/public/fonts/Engrace-Semibold.svg#Engrace-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
