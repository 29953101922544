body {
    @apply p-0 m-0 text-gold text-base font-aldi -md:h-[100svh];
}

#root {
    @apply -md:flex -md:flex-col -md:justify-between -md:h-full;
}

body h1 {
    @apply font-engrace text-[56px] xl:text-[80px] leading-[50.4px] xl:leading-[72px] uppercase font-semibold mb-4;
}

main {
    @apply md:min-h-[calc(100vh-150px-132px)] 2xl:min-h-[calc(100vh-150px-68px)] relative;
}

header,
footer {
    @apply relative;
}

header div.container {
    @apply max-w-full xl:max-w-[692px] 2xl:max-w-[1056px];
}

.decor:before {
    @apply fixed top-0 left-0 right-0 bottom-0 block z-[2] bg-[linear-gradient(180deg,rgba(0,0,0,0.3)1.64%,rgba(0,20,49,0)20.8%,rgba(0,20,49,0)78.29%,rgba(0,0,0,0.6)97.45%)] xl:bg-[linear-gradient(180deg,rgba(0,0,0,0.3)1.64%,rgba(0,20,49,0)39.96%,rgba(0,20,49,0)59.13%,rgba(0,0,0,0.3)97.45%)];
    content: '';
}

.decor .top-l,
.decor .top-r,
.decor .bottom-l,
.decor .bottom-r {
    @apply fixed xl:block z-[1];
}

.decor .top-l {
    @apply top-0 left-0 w-[169px] h-[169px] bg-decor-xl-l;
}

.decor .bottom-l {
    @apply bottom-0 left-0 w-[169px] h-[240px] bg-decor-xl-l;
}

.decor .top-r {
    @apply top-0 right-0 w-[149px] h-[149px] bg-decor-xl-r;
}

.decor .bottom-r {
    @apply bottom-0 right-0 w-[149px] h-[240px] bg-decor-xl-r;
}

div.container {
    @apply px-4 max-w-[1056px] mx-auto;
}

.container-sm,
.container-xs {
    @apply px-4 max-w-[680px] mx-auto;
}

.container-xs {
    @apply max-w-[520px]
}

footer.footer {
    @apply text-sm;
}

.footer ul {
    @apply flex flex-wrap justify-center mb-2 2xl:mb-0;
}

.footer li {
    @apply mx-[10px] mb-3 2xl:mb-0;
}

.footer .footer-menu a {
    @apply underline hover:no-underline;
}

.btn {
    @apply inline-block align-top rounded-full p-[2px] bg-[linear-gradient(360deg,#AB7329_0%,#E5AC61_100%)];
}

.btn span {
    @apply block text-lg leading-none font-bold text-blue rounded-full px-5 pb-[14px] pt-[12px] bg-main-gold bg-cover bg-[left_top];
}

.btn.btn-header span {
    @apply p-0 h-[44px] w-[44px] flex items-center justify-center;
    background-size: 100px;
}

body.modal-active {
    @apply fixed overflow-hidden top-0 left-0 right-0;
}

.modal {
    @apply fixed top-0 left-0 right-0 z-10 h-full md:h-screen;
}

body.modal-active .modal .overlay {
    @apply absolute top-0 left-0 right-0 h-screen;
    background: rgba(25, 70, 127, 0.75);
    backdrop-filter: blur(24px);
}

body.modal-active .modal .container {
    @apply relative;
}

body.modal-active header.header .logo {
    @apply relative z-20;
}

.modal .overlay,
.modal .content {
    @apply opacity-0 transition-opacity duration-300 ease-in-out;
}

.modal .content {
    @apply flex flex-col justify-between h-full;
}

body.modal-active .modal .overlay,
body.modal-active .modal .content {
    @apply opacity-100;
}

.modal .overlay {
    @apply absolute top-0 left-0 right-0 h-screen;
    background: rgba(25, 70, 127, 0.75);
    backdrop-filter: blur(24px);
}

body:not(.modal-active) .modal.modal-menu main {
    @apply h-auto min-h-0;
}

.modal.modal-menu main {
    @apply flex items-center justify-center min-h-[0] md:pb-6 xl:pb-12;
}

.menu li {
    @apply mb-4 xl:mb-8;
}

.menu li a {
    @apply font-engrace text-[64px] xl:text-[80px] leading-[56.7px] xl:leading-[72px] uppercase;
}

.home-page .btn-header {
    @apply hidden;
}

.result-block li {
    @apply rounded-lg p-[2px] mb-1;
    background: linear-gradient(360deg, #AB7329 0%, #E5AC61 100%);
    box-shadow: 20px 50px 25px 0 rgba(0,0,0,.4);
}

.result-block li > div  {
    @apply block relative text-gold text-center rounded-lg pt-[26px] pb-[18px] px-[70px] overflow-hidden;
    background: linear-gradient(150.64deg, #19467F 0%, #1D3D6C 100%);
    content: '';
}

.result-block li h3 {
    @apply text-2xl leading-none font-bold mb-4;
}

.result-block li p {
    @apply text-sm mb-2 leading-none tracking-[0.28px];
}

.result-block li.message {
    @apply md:mb-20;
}

.result-block li.message > div {
    @apply pt-[44px] pb-[34px];
}

.result-block li.message > div p {
    @apply text-base;
}

.result-block.final li.message > div p {
    @apply text-lg leading-[28px] xl:text-[22px] xl:leading-[36px];
}

.decor-item-left:before,
.decor-item-left:after,
.decor-item-right:before,
.decor-item-right:after {
    @apply absolute block w-[66px] h-[57px] bg-no-repeat bg-decor-result;
    content: '';
}

.decor-item-left:before {
    @apply left-0 top-0;
}

.decor-item-right:after {
    @apply bottom-0 right-0 rotate-180;
}

.decor-item-right:before {
    @apply right-0 top-0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.decor-item-left:after {
    @apply left-0 bottom-0;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}
