.calendar-page .decor:before {
    @apply bg-[linear-gradient(180deg,rgba(0,0,0,0.3)1.64%,rgba(0,20,49,0)20.8%,rgba(0,20,49,0)78.29%,rgba(0,0,0,0.6)97.45%)] xl:bg-[linear-gradient(180deg,rgba(0,0,0,0.5)1.64%,rgba(0,20,49,0)39.96%,rgba(0,20,49,0)59.13%,rgba(0,0,0,0.5)97.45%)];
}

.calendar-page .decor .top-l,
.calendar-page .decor .top-r,
.calendar-page .decor .bottom-l,
.calendar-page .decor .bottom-r {
    @apply w-[165px] h-[198px] bg-decor-calendar;
}

.calendar-page .decor .bottom-r {
    @apply rotate-180;
}

.calendar-page .decor .top-r {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.calendar-page .decor .bottom-l {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.tile {
    @apply block col-span-2 bg-auto bg-[top_left] bg-no-repeat relative z-[1];
    background: linear-gradient(360deg, #AB7329 0%, #E5AC61 100%);
}

.tile:before {
    @apply absolute top-[1px] left-[1px] right-[1px] bottom-[1px] block bg-main-gold bg-cover bg-[left_top];
    background-size: 500px auto;
    content: '';
}

.tile .cover {
    @apply block bg-auto bg-[top_left] bg-no-repeat absolute top-0 left-0 right-0 bottom-0;
    background: linear-gradient(360deg, #AB7329 0%, #E5AC61 100%);
}

.tile .cover:before {
    @apply absolute top-[1px] left-[1px] right-[1px] bottom-[1px] block bg-auto bg-[top_left] bg-no-repeat;
    background-image: url("/src/img/decor-tile.png"), linear-gradient(150.64deg, #19467F 0%, #1D3D6C 100%);
    content: '';
}

.tile.active:not(.open):hover .cover {
    @apply w-[calc(100%-25px)]  shadow-none top-[-5px] bottom-[6px];
    -webkit-transform: skewY(-4deg);
    transform: skewY(-4deg);
}

.tile.active {
    @apply cursor-pointer;
}

.tile.active .cover:before {
    @apply bg-main-gold bg-cover bg-[left_top];
}

.tile.active .cover:after {
    @apply absolute top-[1px] left-[1px] bg-decor-tile bg-auto block w-[46px] h-[39px] opacity-100 transition-all duration-300 ease-in-out;
    content: '';
}

.tile.active,
.tile.completed {
    @apply z-[2];
}

.tile.active.open {
    @apply z-[3];
}

.tile.active .cover,
.tile.completed .cover {
    @apply w-[calc(100%-3px)] shadow-[4px_4px_0_0_rgba(0,0,0,0.4)] top-[-1px] bottom-[2px] transition-all duration-300 ease-in-out z-[1];
    -webkit-transform: skewY(-1.2deg);
    transform: skewY(-1.2deg);
}

.tile .cover svg {
    @apply absolute block left-[30px] bottom-[-19px] blur-[1px] w-[calc(100%-36px)] z-[-1] transition-all duration-300 ease-in-out;
}

.tile.active:not(.open):hover .cover svg {
    @apply absolute block left-[6px] bottom-[-18px] w-[calc(100%-14px)];
}

.tile.col-span-1.active:not(.open):hover .cover {
    @apply w-[calc(100%-18px)] top-[-2px] bottom-[2px];
}

.tile.col-span-1.active:not(.open):hover .cover svg {
    @apply left-[4px] bottom-[-16px] w-[calc(100%-10px)];
}

.tile.col-span-1 .cover svg {
    @apply left-[18px] w-[calc(100%-22px)];
}

.tile.active  .cover:before,
.tile.completed  .cover:before {
    @apply border-b border-[#AE7231];
}

.tile.completed:after {
    @apply absolute top-[1px] left-[1px] bottom-[1px] w-[75%] z-[1];
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0, rgba(0, 20, 49, 0) 100%);
    content: '';
}

.tile.active.open .cover {
    @apply w-[calc(100%-40px)] shadow-none left-[1px] bottom-0 top-0;
    transform-origin: 0 100%;
    transform: rotateY(180deg) skew(0deg, -6deg);
}

.tile.active.open .cover:before {
    @apply left-0  bg-[top_right];
    background-image: linear-gradient(130deg, rgba(171, 115, 41, 0.6) 0.92%, rgba(229, 172, 97, 0.2) 99.08%), url("/src/img/bg-gold.webp");
}

.tile.active.open .cover:after,
.tile.active.open .day {
    @apply opacity-0;
}

.tile.completed .title-page {
    @apply text-gold z-[2];
}

.tile .title-page {
    @apply relative text-blue flex flex-col justify-center items-center h-full w-full;
}

.tile .title-page span {
    @apply text-sm leading-none font-bold mt-[5px];
}

.tile .day {
    @apply absolute top-[10px] right-[12px] text-[28px] leading-none font-bold text-gold;
}

.tile.active .day {
    @apply text-blue opacity-100 transition-all duration-200 ease-in-out;
}

.tile.active:before {
    box-shadow: inset 0 0 20px 0 rgba(0,0,0,0.5);
}

.tile.active.open .cover svg {
    @apply left-[3px] w-[calc(100%-8px)];
    transform: skewY(4deg);
}

.tile.active.open.col-span-1 .cover svg {
    @apply w-[54px] bottom-[-17px];
}

.mobile-calendar .tile.day-18,
.mobile-calendar .tile.day-15,
.mobile-calendar .tile.day-5,
.mobile-calendar .tile.day-22,
.mobile-calendar .tile.day-19,
.mobile-calendar .tile.day-6,
.mobile-calendar .tile.day-23,
.mobile-calendar .tile.day-3,
.mobile-calendar .tile.day-20,
.mobile-calendar .tile.day-10 {
    @apply -xl:col-span-1 -xl:row-span-1;
}

.mobile-calendar .tile.day-13,
.mobile-calendar .tile.day-12,
.mobile-calendar .tile.day-8,
.mobile-calendar .tile.day-11,
.mobile-calendar .tile.day-1,
.mobile-calendar .tile.day-21,
.mobile-calendar .tile.day-7,
.mobile-calendar .tile.day-16,
.mobile-calendar .tile.day-2,
.mobile-calendar .tile.day-17 {
    @apply -xl:row-span-2;
}

.mobile-calendar .tile.day-5,
.mobile-calendar .tile.day-3 {
    @apply -xl:col-start-4 -xl:row-start-4 top-[-89px] h-[85px];
}

.mobile-calendar .tile.day-12,
.mobile-calendar .tile.day-7 {
    @apply top-[-85px];
}

.mobile-calendar .tile.day-1,
.mobile-calendar .tile.day-17 {
    @apply top-[-87px];
}

.mobile-calendar .tile .cover svg {
    @apply bg-shadow-icon opacity-70;
}

.mobile-calendar .tile.open.col-span-1 {
    @apply z-[4];
}

.mobile-calendar .tile.open.col-span-1 .cover svg {
    @apply left-[-14px];
}

.mobile-calendar .tile.active {
    @apply z-[3];
}

.modal.modal-loader {
    @apply z-20;
}

.modal.modal-loader header .btn-header {
    @apply hidden;
}

body.modal-active .modal.modal-loader .overlay {
    @apply bg-main-blue bg-no-repeat bg-center bg-cover;
}

body.modal-active .modal.modal-loader .overlay:before {
    @apply fixed top-0 left-0 right-0 bottom-0 block z-[2] bg-[linear-gradient(180deg,rgba(0,0,0,0.3)1.64%,rgba(0,20,49,0)20.8%,rgba(0,20,49,0)78.29%,rgba(0,0,0,0.3)97.45%)] xl:bg-[linear-gradient(180deg,rgba(0,0,0,0.3)1.64%,rgba(0,20,49,0)39.96%,rgba(0,20,49,0)59.13%,rgba(0,0,0,0.3)97.45%)];
    content: '';
}

.modal.modal-loader .progress-bar {
    @apply w-full bg-[linear-gradient(180deg,#FFF_17.5%,#55C3F0_100%)];
    animation: progressAnimation 1.7s;
}

@keyframes progressAnimation {
    from { width: 0 }
    to   { width: 100% }
}

.modal.modal-loader .loading-decor-l {
    @apply fixed top-0 left-0 block w-1/2 h-screen xl:bg-decor-xl-l bg-no-repeat bg-[auto_100%] bg-[left_top];
}

.modal.modal-loader .loading-decor-r {
    @apply fixed top-0 right-0 block w-1/2 h-screen xl:bg-decor-xl-r bg-no-repeat bg-[auto_100%] bg-[right_top];
}

.modal.modal-loader .loading-decor-l:before {
    @apply absolute top-0 left-0 w-[172px] h-[150px] bg-decor-xl-l bg-[right_top] xl:hidden;
    content: '';
}

.modal.modal-loader .loading-decor-l:after {
    @apply absolute bottom-0 left-0 w-[172px] h-[320px] bg-decor-xl-l bg-[right_bottom] xl:hidden;
    content: '';
}

.modal.modal-loader .loading-decor-r:before {
    @apply absolute top-0 right-0 w-[152px] h-[150px] bg-decor-xl-r bg-[left_top] xl:hidden;
    content: '';
}

.modal.modal-loader .loading-decor-r:after {
    @apply absolute bottom-0 right-0 w-[152px] h-[320px] bg-decor-xl-r bg-[left_bottom] xl:hidden;
    content: '';
}

.modal .game-container {
    @apply bg-[linear-gradient(360deg,#AB7329_0%,#E5AC61_100%)];
}

.modal .game-container > iframe {
    @apply fixed top-0 left-0 w-screen h-screen max-h-full;
}
